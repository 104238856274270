













/* eslint-disable @typescript-eslint/no-explicit-any */
  import { Message } from '@/store/modules/user'
  import { defineComponent } from '@vue/composition-api'
  import { mapState, mapActions } from 'vuex'

  export default defineComponent({
    name: 'DashboardCoreMessage',

    data: () => ({
    }),

    computed: {
      ...mapState('user', [
        'message',
      ]),
      messageLevel (): any {
        const message = this.message as unknown as Message|null
        return message ? message.level : 'success'
      },
      messageDisplayed (): any {
        const message = this.message as unknown as Message|null
        return message ? message.message : ''
      },
      showMessage: {
        get (): boolean {
          return this.message != null
        },
        set (value: any): void {
          if (!value) {
            this.deleteMessage()
          }
        },
      },
      timeout (): number {
        const level = this.messageLevel
        if (level === 'error') {
          return -1
        }
        return 5000
      },
    },

    methods: {
      ...mapActions('user', [
        'deleteMessage',
      ]),
    },
  })
